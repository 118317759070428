.upload-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.upload-box {
  width: 300px;
  height: 150px;
  background-image: radial-gradient(
    circle at 50% 20%,
    rgb(43 62 133) 5%,
    rgb(20 34 85) 90%
  );
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  color: #eaeaea;
}

.upload-label {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
}

.upload-icon {
  font-size: 36px;
  cursor: pointer;
}

.file-name {
  font-size: 12px;
  color: #ccc;
  margin-top: 10px;
  text-align: center;
}
